import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppResolver } from './app.resolver';
import { PrivateGuard } from './auth/guards/private.guard';
import { PublicGuard } from './auth/guards/public.guard';
import { FullTemplateComponent } from './core/templates/full-template/full-template.component';
import { FooterLogoType } from './core/components/layout-footer/layout-footer.component';
import { RgpdGuard } from './core/rgpd/rgpd.guard';

const routes: Routes = [
  {
    canActivate: [PublicGuard],
    path: "cc-login-result",
    loadChildren: () => import("src/app/pages/cc-login-result/cc-login-result.module").then(m => m.CCLoginResultModule),
    data: { breadcrumb: "LOGIN.LOGIN", footer: FooterLogoType.AMA },
  },
  {
    path: "employment-event/qr-code/:id",
    loadChildren: () => import("@fi-sas/webpage/pages/employment-event-qr-code/employment-event-qr-code.module").then(m => m.EmploymentEventQRCodeModule),
    data: { breadcrumb: "QRCODE_EVENT.BREADCRUMB" },
  },
  {
    path: "auth",
    component: FullTemplateComponent,
    resolve: [AppResolver],
    children: [
      {
        path: "login",
        loadChildren: () => import("src/app/pages/login/login.module").then(m => m.LoginModule),
        data: { breadcrumb: "LOGIN.LOGIN" },
      },
      {
        path: "reset-password",
        loadChildren: () => import(
          "src/app/pages/reset-password/reset-password.module").then(m => m.ResetPasswordModule),
        data: { breadcrumb: "RESET_PASSWORD.TITLE" },
      },
      {
        path: "new-password",
        loadChildren: () => import(
          "src/app/pages/new-password/new-password.module").then(m => m.NewPasswordModule),
        data: { breadcrumb: "CHANGE_PASSWORD.NEW_PASSWORD" },
      },
      {
        path: "new-verification-code",
        loadChildren: () => import(
          "src/app/pages/new-verification-code/new-verification-code.module").then(m => m.NewVerificationCodeModule),
        data: { breadcrumb: "NEW_VERIFICATION.TITLE" },
      },
      {
        path: "ssoError",
        loadChildren: () => import("src/app/pages/sso-error/sso-error.module").then(m => m.SsoErrorModule),
        data: { breadcrumb: "SSO_ERROR.BREADCRUMB" },
      },
      { path: "", redirectTo: "login", pathMatch: "full" },
    ],
    data: {
      breadcrumb: "LOGIN.AUTHENTICATION",
      footer: FooterLogoType.AMA,
    },
    canActivate: [PublicGuard],
  },
  {
    path: "",
    component: FullTemplateComponent,
    resolve: [AppResolver],
    children: [
      {
        path: "dashboard",
        loadChildren: () => import(
          "src/app/pages/dashboard/dashboard.module").then(m => m.DashboardModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.SERVICES", service_id: 22 },
        canActivate: [RgpdGuard]
      },
      {
        path: "privateaccommodation",
        loadChildren: () => import(
          "./modules/private-accommodation/private-accommodation.module").then(m => m.PrivateAccommodationModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.PRIVATEACCOMMODATION" },
      },
      {
        path: "volunteering",
        loadChildren: () => import("./modules/volunteering/volunteering.module").then(m => m.VolunteeringModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.VOLUNTEERING" },
      },
      {
        path: "news",
        loadChildren: () => import("./modules/news/news.module").then(m => m.NewsModule),
        data: { breadcrumb: "DASHBOARD.WIDGETS.POSTS.TITLE" },
      },
      {
        path: "account-verification",
        loadChildren: () => import(
          "src/app/pages/account-verification/account-verification.module").then(m => m.AccountVerificationModule),
        data: { breadcrumb: "ACCOUNT_VERIFICATION.TITLE" },
      },
      {
        path: "unauthorized",
        loadChildren: () => import(
          "src/app/pages/unauthorized/unauthorized.module").then(m => m.UnauthorizedModule),
        data: { breadcrumb: "UNAUTHORIZED.UNAUTHORIZED" },
      },
      {
        path: "regulations",
        loadChildren: () => import(
          "./pages/regulations/regulations.module").then(m => m.RegulationsModule),
      },
      {
        path: "acessibilidade",
        loadChildren: () => import(
          "./pages/accessibility/accessibility.module").then(m => m.AccessibilityModule),
      },
      {
        path: "calendar",
        loadChildren: () => import(
          "./modules/calendar/calendar.module").then(m => m.CalendarModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.CALENDAR" },
      },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
    ],
    canActivateChild: [PublicGuard],
    data: { breadcrumb: null, footer: FooterLogoType.AMA, },
  },
  {
    path: "",
    component: FullTemplateComponent,
    resolve: [AppResolver],
    children: [
      {
        path: "profile",
        loadChildren: () => import("src/app/pages/profile/profile.module").then(m => m.ProfileModule),
        data: { breadcrumb: "CORE.PROFILE" },
        canActivate: [PrivateGuard],
      },
      {
        path: "personal-data",
        loadChildren: () => import(
          "src/app/pages/personal-data/personal-data.module").then(m => m.PersonalDataModule),
        data: { breadcrumb: "PROFILE_PAGE.PERSONAL_DATA" },
        canActivate: [PrivateGuard],
      },
      {
        path: "firstLogin",
        loadChildren: () => import(
          "src/app/pages/first-login/first-login.module").then(m => m.FirstLoginModule),
        data: { breadcrumb: "LOGIN.FIRST_LOGIN.TITLE" },
        canActivate: [PrivateGuard],
      },
      {
        path: "change-pin",
        loadChildren: () => import(
          "src/app/pages/change-pin/change-pin.module").then(m => m.ChangePinModule),
        data: { breadcrumb: "PROFILE_PAGE.CHANGE_PIN" },
        canActivate: [PrivateGuard],
      },
      {
        path: "change-password",
        loadChildren: () => import(
          "src/app/pages/change-password/change-password.module").then(m => m.ChangePasswordModule),
        data: { breadcrumb: "PROFILE_PAGE.CHANGE_PASSWORD" },
        canActivate: [PrivateGuard],
      },
      {
        path: "disable-account",
        loadChildren: () => import(
          "src/app/pages/disable-account/disable-account.module").then(m => m.DisableAccountModule),
        data: { breadcrumb: "PROFILE_PAGE.DISABLE_ACCOUNT" },
        canActivate: [PrivateGuard],
      },
      {
        path: "qrcode",
        loadChildren: () => import(
          "src/app/pages/qrcode-ticket/qrcode-ticket.module").then(m => m.QrcodeTicketModule),
        data: { breadcrumb: "QRCODE.TITLE" },
        canActivate: [PrivateGuard],
      },
      {
        path: "not-found",
        loadChildren: () => import(
          "src/app/pages/not-found-page/not-found-page.module").then(m => m.NotFoundPageModule),
        data: { breadcrumb: "MISC.PAGE_NOT_FOUND" },
      },
      {
        path: "accommodation",
        loadChildren: () => import(
          "./modules/accommodation/accommodation.module").then(m => m.AccommodationModule),
        data: {
          breadcrumb: "DASHBOARD.BUTTON.ACCOMMODATION",
          scope: "accommodation",
        },
        canActivate: [PrivateGuard],
      },
      {
        path: "alimentation",
        loadChildren: () => import(
          "./modules/alimentation/alimentation.module").then(m => m.AlimentationModule),
        data: {
          breadcrumb: "DASHBOARD.BUTTON.ALIMENTATION",
          scope: "alimentation",
        },
        canActivate: [PrivateGuard],
      },
      {
        path: "queue",
        loadChildren: () => import("./modules/queue/queue.module").then(m => m.QueueModule),
        data: { breadcrumb: "QUEUE.TITLE_QUEUE", scope: "queue" },
        canActivate: [PrivateGuard],
      },
      {
        path: "privateaccommodation",
        loadChildren: () => import(
          "./modules/private-accommodation/private-accommodation.module").then(m => m.PrivateAccommodationModule),
        data: {
          breadcrumb: "DASHBOARD.BUTTON.PRIVATEACCOMMODATION",
          scope: "private_accommodation",
        },
        canActivate: [PrivateGuard],
      },
      {
        path: "current-account",
        loadChildren: () => import(
          "./modules/current-account/current-account.module").then(m => m.CurrentAccountModule),
        data: {
          breadcrumb: "DASHBOARD.BUTTON.CURRENT_ACCOUNT",
          scope: "current_account",
        },
        canActivate: [PrivateGuard],
      },
      {
        path: "shopping-cart",
        loadChildren: () => import(
          "./modules/cart-shopping/cart-shopping.module").then(m => m.CartShoppingModule),
        data: { breadcrumb: "SHOPPING_CART.PAGE.BREADCRUMB", scope: 'payments:cart' },
        canActivate: [PrivateGuard],
      },
      {
        path: "u-bike",
        loadChildren: () => import("./modules/u-bike/u-bike.module").then(m => m.UBikeModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.UBIKE", scope: "u_bike" },
        canActivate: [PrivateGuard],
      },
      {
        path: "mobility",
        loadChildren: () => import("./modules/mobility/mobility.module").then(m => m.MobilityModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.MOBILITY", scope: "bus" },
        canActivate: [PrivateGuard],
      },
      {
        path: "social-support",
        loadChildren: () => import(
          "./modules/social-support/social-support.module").then(m => m.SocialSupportModule),
        data: {
          breadcrumb: "DASHBOARD.BUTTON.SOCIAL_SUPPORT",
          scope: "social_scholarship",
        },
        canActivate: [PrivateGuard],
      },
      {
        path: "volunteering",
        loadChildren: () => import(
          "./modules/volunteering/volunteering.module").then(m => m.VolunteeringModule),
        data: {
          breadcrumb: "DASHBOARD.BUTTON.VOLUNTEERING",
          scope: "volunteering",
        },
        canActivate: [PrivateGuard],
      },
      {
        path: "calendar",
        loadChildren: () => import(
          "./modules/calendar/calendar.module").then(m => m.CalendarModule),
        data: {
          breadcrumb: "DASHBOARD.BUTTON.CALENDAR",
          scope: "calendar",
        },
        canActivate: [PrivateGuard],
      },
      {
        path: "health",
        loadChildren: () => import("./modules/health/health.module").then(m => m.HealthModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.HEALTH", scope: "health" },
        canActivate: [PrivateGuard],
      },
      {
        path: "emergency-fund",
        loadChildren: () => import("./modules/emergency-fund/emergency-fund.module").then(m => m.EmergencyFundModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.EMERGENCY_FUND", scope: "emergency_fund" },
        canActivate: [PrivateGuard],
      },
      {
        path: "requests",
        loadChildren: () => import("./modules/requests/requests.module").then(m => m.RequestsModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.REQUESTS", scope: "requests" },
        canActivate: [PrivateGuard],
      },
      {
        path: "refill-h2o",
        loadChildren: () => import("./modules/refill-h2o/refill-h2o.module").then(m => m.RefillH2oModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.REFILL_H2O", scope: "refill_h2o" },
        canActivate: [PrivateGuard],
      },
      {
        path: "maintenance",
        loadChildren: () => import("./modules/maintenance/maintenance.module").then(m => m.MaintenanceModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.MAINTENANCE", scope: "maintenance" },
        canActivate: [PrivateGuard],
      },
      {
        path: "job-portal",
        loadChildren: () => import("./modules/job-portal/job-portal.module").then(m => m.JobPortalModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.JOB_PORTAL", footer: FooterLogoType.PRR, scope: "job_portal" },
        canActivate: [PrivateGuard],
      },
      {
        path: "casual-accommodation",
        loadChildren: () => import("./modules/casual-accommodation/casual-accommodation.module").then(m => m.CasualAccommodationModule),
        data: { breadcrumb: "DASHBOARD.BUTTON.CASUAL_ACCOMMODATION", footer: FooterLogoType.PRR, scope: null }
      },
      {
        path: "bus-validator",
        loadChildren: () => import(
          "src/app/modules/mobility/modules/ticket-validator/ticket-validator.module").then(m => m.BusTicketValidatorModule),
        data: { breadcrumb: "MOBILITY.BREADCRUMB_BUS_VALIDATOR", scope: "bus"  },
        canActivate: [PrivateGuard],
      },
    ],
    canActivateChild: [PrivateGuard],
    data: { breadcrumb: null, footer: FooterLogoType.AMA, },
  },
  {
    path: "",
    component: FullTemplateComponent,
    resolve: [AppResolver],
    children: [
      {
        path: "**",
        loadChildren: () => import(
          "src/app/pages/not-found-page/not-found-page.module").then(m => m.NotFoundPageModule),
        data: { breadcrumb: "MISC.PAGE_NOT_FOUND" },
      },
    ],
    data: { breadcrumb: null, footer: FooterLogoType.AMA, },
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: "disabled" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
