<div nz-row>
    <div nz-col nzSpan="24" class="payment_content">
        <div>
            <div class="payment_content__title" *ngIf="noPaymehtMethods(available_payment_methods) else isMethods">
                <label><b>{{'CURRENT_ACCOUNT.NO_METHODS' | translate}}</b></label>
            </div>
            <ng-template #isMethods>
                <div class="payment_content__title">
                    <label><b>{{(mode == 'CHARGE' ? 'CURRENT_ACCOUNT.PAYMENTS.TITLE' :
                            'CURRENT_ACCOUNT.PAYMENTS.TITLE_PAY') | translate}}</b></label>
                </div>
            </ng-template>
        </div>
        <div *ngFor="let paymentMethod of validPaymentMethods(available_payment_methods)" class="payment_method">
            <div nz-row (click)="selectPaymentMethod(paymentMethod)">
                <div nz-col nzSpan="24" class="payment_method__line">
                    <div class="payment_method__line__radio">
                        <div *ngIf="selectedMethod && selectedMethod.id == paymentMethod.id"
                            class="payment_method__line__radio__core">
                        </div>
                    </div>
                    <div class="payment_method__line__name">
                        {{paymentMethod.name}}
                    </div>
                    <img class="payment_method__line__icon"
                        [src]="'assets/imgs/'+ getIconByTag(paymentMethod) + '.svg'">
                </div>
            </div>

            <div nz-row *ngIf="selectedMethod && selectedMethod.id == paymentMethod.id" class="payment_method__content">
                <div nz-col nzSpan="24">


                    <div *ngIf="mode == 'PAY' && selectedMethod.id != '0bbd1f3f-8720-4075-880c-bf3d789efceb'" nz-row
                        nzGutter="8" nzAlign="middle" nzJustify="center" style="padding-top:25px">
                        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="10" [nzLg]="8" [nzXl]="8" [nzXXl]="8">
                            <div class="box-current-account-before">
                                <div nz-row>
                                    <label><b>{{'CURRENT_ACCOUNT.PAYMENTS.AMOUNTPAY' | translate}}</b></label>
                                </div>
                                <div nz-row class="payment_method__content__box-input">
                                    {{valueToPay | number:'1.2-2'}} €
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="mode == 'CHARGE'" nz-row nzGutter="16" nzAlign="middle" nzJustify="center">
                        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="8" [nzXl]="8" [nzXXl]="8"
                            style="padding-top:25px">
                            <div nz-row>
                                <label><b>{{'CURRENT_ACCOUNT.PAYMENTS.AMOUNTCHARGE' | translate}}</b></label>
                            </div>
                            <div style="text-align: center;">
                                <nz-input-number [(ngModel)]="valueCharge" [nzMin]="0" [nzStep]="1" [nzPrecision]="2" class="input-value" (ngModelChange)="changeValue()" style="width: 100%;">
                                  </nz-input-number>
                            </div>
                            <div nz-row style="padding-top:10px" *ngIf="selectedMethod.min_charge_amount && selectedMethod.max_charge_amount">
                              {{'CURRENT_ACCOUNT.PAYMENTS.MIN_MAX_MESSAGE_1' | translate}} {{selectedMethod.min_charge_amount}}€ {{'CURRENT_ACCOUNT.PAYMENTS.MIN_MAX_MESSAGE_2' | translate}} {{selectedMethod.max_charge_amount}}€.
                            </div>
                            <div nz-row style="padding-top:10px" *ngIf="selectedMethod.min_charge_amount && !selectedMethod.max_charge_amount">
                              {{'CURRENT_ACCOUNT.PAYMENTS.MIN_MESSAGE_1' | translate}} {{selectedMethod.min_charge_amount}}€.
                            </div>
                            <div nz-row style="padding-top:10px" *ngIf="!selectedMethod.min_charge_amount && selectedMethod.max_charge_amount">
                              {{'CURRENT_ACCOUNT.PAYMENTS.MAX_MESSAGE_1' | translate}} {{selectedMethod.max_charge_amount}}€.
                            </div>
                        </div>
                    </div>

                    <!-- Begin BEFORE/AFTER Balances-->

                    <div *ngIf="mode == 'CHARGE' || selectedMethod.id == '0bbd1f3f-8720-4075-880c-bf3d789efceb'" nz-row
                        nzGutter="8" nzAlign="middle" nzJustify="center" style="padding-top:25px">
                        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="10" [nzLg]="8" [nzXl]="8" [nzXXl]="8">
                            <div class="box-current-account-before">
                                <div nz-row>
                                    <label><b>{{'CURRENT_ACCOUNT.PAYMENTS.CURRENTACCOUNTBEFORE' |
                                            translate}}</b></label>
                                </div>
                                <div nz-row class="payment_method__content__box-input">
                                    {{currentBalance | number:'1.2-2'}} €
                                </div>
                            </div>
                        </div>
                        <div nz-col [nzXs]="0" [nzSm]="0" [nzMd]="2" [nzLg]="2" [nzXl]="2" [nzXXl]="2"
                            style="font-size: 40px; text-align: center;">
                            <span nz-icon nzType="right" nzTheme="outline"></span>
                        </div>
                        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="0" [nzLg]="0" [nzXl]="0" [nzXXl]="0"
                            style="text-align: center; font-size: 30px;">
                            <span nz-icon nzType="down" nzTheme="outline"></span>
                        </div>
                        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="10" [nzLg]="8" [nzXl]="8" [nzXXl]="8">
                            <div class="box-current-account-after">
                                <div nz-row>
                                    <label><b>{{'CURRENT_ACCOUNT.PAYMENTS.CURRENTACCOUNTAFTER' | translate}}</b></label>
                                </div>
                                <div nz-row class="payment_method__content__box-input">
                                    {{totalValue | number:'1.2-2'}} €
                                </div>
                            </div>
                        </div>
                    </div>

                    <div nz-row nzGutter="16" nzAlign="middle" nzJustify="center">

                        <!-- mbwayy phone -->


                        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="8" [nzXl]="8" [nzXXl]="8"
                            style="padding-top:25px" *ngIf="isMbWay()">
                            <div nz-row>
                                <label><b>{{'CURRENT_ACCOUNT.PAYMENTS.NUMBER' | translate}}</b></label>
                            </div>

                            <div nz-row style="padding-top:10px">
                                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                                    <input [(ngModel)]="mobileNumber" nz-input class="input-value-current-account">
                                </nz-input-group>
                                <ng-template #addOnBeforeTemplate>
                                    <nz-select [ngModel]="mobileIndicator" [nzDropdownMatchSelectWidth]="false"
                                        [nzCustomTemplate]="defaultTemplate" nzShowSearch nzServerSearch
                                        (nzOnSearch)="searchCountry($event)" style="min-width: 100px">
                                        <nz-option *ngFor="let country of contries" nzCustomContent
                                            [nzLabel]="country.dial_code" [nzValue]="country">
                                            {{country.emoji}} ({{country.dial_code}}) {{country.nome}}
                                        </nz-option>
                                    </nz-select>
                                </ng-template>
                                <ng-template #defaultTemplate let-selected>
                                    {{selected.nzValue.emoji}} {{selected.nzValue.dial_code}}
                                </ng-template>
                            </div>
                        </div>
                    </div>


                    <!-- BEGIN BUTTON PAYMENT FORM -->
                    <div nz-row>
                        <div nz-col nzSpan="24" class="payment_method__content__button_box">
                            <button nz-button type="button" class="payment_method__content__button_box--movement"
                                (click)="selectedMethod = null">
                                {{'CURRENT_ACCOUNT.PAYMENTS.CANCEL' |
                                translate}}
                            </button>

                            <button nz-button type="button" class="payment_method__content__button_box--charge"
                                [disabled]="disabledConfirmButton()" [nzLoading]="loadingPayment" (click)="confirm()">
                                <!-- (method == 'REFMB_AMA' || method == 'REFMB_SIBS') ? isATM(balance.account_id, null) : isPaypal() -->
                                {{'CURRENT_ACCOUNT.PAYMENTS.CONFIRM' | translate}}
                            </button>
                        </div>
                    </div>
                    <!-- END BUTTON PAYMENT FORM -->


                    <!-- BEGIN INFOS-->
                    <div nz-row *ngIf="isCard()" class="text-detail text-center">
                        <span> {{'CURRENT_ACCOUNT.PAYMENTS.DESCRIPTION3_VISA' | translate}} </span>
                    </div>
                    <div nz-row *ngIf="isPaypal()" class="text-detail text-center">
                        <span> {{'CURRENT_ACCOUNT.PAYMENTS.DESCRIPTION_PAYPAL' | translate}} </span>
                    </div>
                    <div nz-row *ngIf="isMbWay()" class="text-detail text-center">
                        <span> {{'CURRENT_ACCOUNT.PAYMENTS.DESCRIPTION1_MBWAY' | translate}} </span> <br>
                        <span> {{'CURRENT_ACCOUNT.PAYMENTS.DESCRIPTION2_MBWAY' | translate}} </span> <br>
                        <span><b> {{'CURRENT_ACCOUNT.PAYMENTS.DESCRIPTION3_MBWAY' | translate}} </b></span>
                    </div>
                    <div nz-row *ngIf="isRefMb()" class="text-detail text-center">
                        <span> {{'CURRENT_ACCOUNT.PAYMENTS.DESCRIPTION1_ATM' | translate}}
                            <br>{{'CURRENT_ACCOUNT.PAYMENTS.DESCRIPTION1_ATM_2' | translate}}</span>
                    </div>
                    <!-- END INFOS-->

                    <div nz-row nzJustify="center" style="margin-top: 16px;"
                        *ngIf="showBankLogos && selectedMethod.id != '0bbd1f3f-8720-4075-880c-bf3d789efceb'">
                        <!--ENAUTICA BANK LOGOS-->
                        <div nz-col nzXs="24" nzMd="12" nzXXl="6" style="text-align: center;">

                            <img class="payment_method__content__bank-logo" src="/assets/imgs/SimboloCGD.jpg">
                            <img class="payment_method__content__bank-logo" src="/assets/imgs/MB.jpg">
                            <img class="payment_method__content__bank-logo" src="/assets/imgs/MBWAY.jpg">
                            <img class="payment_method__content__bank-logo" src="/assets/imgs/MasterCard.jpg">
                            <img class="payment_method__content__bank-logo" src="/assets/imgs/VisaSecure.jpg">
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</div>



<!---------------------------MODAL SUCCESS----------------------->
<nz-modal [nzVisible]="successModal" (nzOnCancel)="successModal = false" (nzOnOk)="successModal = false"
  id="modal-success-payments">
  <ng-container *nzModalContent>
    <div class="img-success">
      <img src="assets/imgs/Success.PNG">
    </div>
    <div class="text-img">
      <label>{{'CURRENT_ACCOUNT.PAYMENTS.TEXT_SUCCESS' | translate}}</label>
    </div>
  </ng-container>
</nz-modal>

<nz-modal [nzVisible]="modalMBWay" nzClosable="false">
  <ng-container *nzModalContent>
    <div class="text-center">
      <div nz-row class="text-img">
        <div nz-col nzSpan="24">
          <label><b>{{'CURRENT_ACCOUNT.ACCOUNT' | translate}}</b></label>
        </div>
      </div>
      <div nz-row style="color:black">
        <div nz-col nzSpan="24">
          <label><b>MB WAY</b></label>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="24">
          <img src="assets/imgs/MBWAY.svg" width="100">
        </div>
      </div>
      <div>
        {{ 'CURRENT_ACCOUNT.PAYMENTS.MBWAY_DETAIL.DESCRIPTION1' | translate }}
      </div>
      <div>

        <nz-progress class="progress" nzType="circle" [nzStatus]="getProgressStatus()"
          [nzPercent]="modalMbWayPercentage" [nzStrokeColor]="{ '100%' : '#0099ff', '0%' : '#ff3300' }"
          [nzFormat]="MBWayFormat">
        </nz-progress>
      </div>
    </div>
  </ng-container>
  <ng-container *nzModalFooter>
    <button nz-button *ngIf="modalMBWayStatus == 'FAILED'" (click)="closeMBWayModal()">{{ 'MISC.RETURN' | translate
      }}</button>
    <button nz-button *ngIf="modalMBWayStatus == 'SUCCESS'" (click)="confirmMBWayModal()">{{ 'MISC.CONFIRM' | translate
      }}</button>
    <button nz-button *ngIf="modalMBWayStatus == 'PENDING'" (click)="closeMBWayModal()">{{ 'MISC.CANCEL' | translate
      }}</button>
  </ng-container>
</nz-modal>

<!---------------------------MODAL ATM----------------------->
<nz-modal [nzVisible]="modalATM" id="modal-atm-payments" [nzFooter]="null">
  <ng-container *nzModalContent>
    <div class="text-center">
      <div nz-row class="text-img">
        <div nz-col nzSpan="24">
          <label><b>{{'CURRENT_ACCOUNT.ACCOUNT' | translate}}</b></label>
        </div>
      </div>
      <div nz-row style="color:black">
        <div nz-col nzSpan="24">
          <label><b>{{'CURRENT_ACCOUNT.PAYMENTS.ATM' | translate}}</b></label>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="24">
          <img src="assets/imgs/Success.PNG" width="100">
        </div>
      </div>
      <div nz-row class="success-atm">
        <div nz-col nzSpan="24">
          <label>{{'CURRENT_ACCOUNT.PAYMENTS.ATM_DETAIL.SUCCESS' | translate}}</label>
        </div>
      </div>
      <div nz-row class="atm-text">
        <div nz-col nzSpan="24">
          <label><b>{{'CURRENT_ACCOUNT.PAYMENTS.ATM_DETAIL.ATM' | translate}}</b></label>
        </div>
      </div>
      <div nz-row class="detail-atm-success">
        <div nz-col nzSpan="24">
          <label>{{'CURRENT_ACCOUNT.PAYMENTS.ATM_DETAIL.DESCRIPTION1' |
            translate}}</label>
        </div>
      </div>
      <div nz-row style="margin-bottom: 20px;">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6" [nzLg]="6" [nzXl]="6" [nzXXl]="6"></div>
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12" class="box-ent-ref">
          <div><label><b>{{'CURRENT_ACCOUNT.PAYMENTS.ATM_DETAIL.ENT' | translate}}</b></label>
            {{dataPayment?.entity}}</div>
          <div><label><b>{{'CURRENT_ACCOUNT.PAYMENTS.ATM_DETAIL.REF' | translate}}</b></label>
            {{dataPayment?.reference}}</div>
          <div><label><b>{{'CURRENT_ACCOUNT.PAYMENTS.ATM_DETAIL.VALUE' | translate}}</b></label>
            {{dataPayment?.value
            | number:'1.2-2'}} €</div>
          <div *ngIf="dataPayment?.expire_at"><label><b>{{'CURRENT_ACCOUNT.PAYMENTS.ATM_DETAIL.EXP' |
                translate}}</b></label>
            {{dataPayment?.expire_at | date: 'mediumDate' }}</div>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6" [nzLg]="6" [nzXl]="6" [nzXXl]="6"></div>
      </div>
      <div nz-row class="detail-atm-success">
        <div nz-col nzSpan="24">
          <label>{{'CURRENT_ACCOUNT.PAYMENTS.ATM_DETAIL.DESCRIPTION2' |
            translate}}</label>
        </div>
      </div>
      <div nz-row style="color:black;">
        <div nz-col nzSpan="24">
          <label><b>{{'CURRENT_ACCOUNT.PAYMENTS.ATM_DETAIL.DESCRIPTION3' |
              translate}}</b></label>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="24">
          <button nz-button type="button" id="button-confirm"
            (click)="closeATMModal()">{{'CURRENT_ACCOUNT.PAYMENTS.CONFIRM'
            |
            translate}}</button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
